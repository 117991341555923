"use strict";

import Vue from 'vue';
import axios from "axios";


const urlHostname = location.protocol + '//' + location.hostname
var urlPort = (location.port ? ':' + location.port : '')
axios.defaults.baseURL = urlHostname + urlPort + '/api/'
// specify URL for local dev
// For development, please change the port number below in accordance with your local environment
if (location.hostname === '127.0.0.1' || location.hostname==='localhost') {
  urlPort=80
  axios.defaults.baseURL = 'http://int-deliveroo.mrshmc.com/api/'
}

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

const callLogApi = (options,data,callBack) => {
    const axiosInstance = axios.create({
        baseURL: axios.defaults.baseURL,
        method: 'POST',
        url: 'accesslog',
         headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Access-Control-Allow-Origin':'*'},
        withCredentials: true,
        origin:true,
        timeout: 1000 * 10,
        data: data
    });
    return axiosInstance(callBack);
};

// Plugin.install = function(Vue) {
//
//   Vue.axios = _axios;
//   window.axios = _axios;
//   Object.defineProperties(Vue.prototype, {
//     axios: {
//       get() {
//         return _axios;
//       }
//     },
//     $axios: {
//       get() {
//         return _axios;
//       }
//     },
//   });
// };
//
// Vue.use(Plugin)
//
// export default Plugin;

export default {
  getMasterData (callBack) {
    return axios
        .get('/masterdata')
        .then((response) => {
          callBack(response.data)
        })
        .catch((e) => {
          if (e.response) {
            console.log('got response from server.', e)
            if (e.response.status >= 500) {
              alert(e.response.data.Message)
            } else {
              alert(e.message)
            }
          } else if (e.request) {
            console.log('no response from server.')
            console.log('Message:' + e.message)
          } else {
            console.log('getMasterData error!:' + e.message)
          }
        })
  },
  postClaim (item,callBack) {
    return axios
        .post('/claimform',item)
        .then((response) => {
          callBack(response)
        })
        .catch((e) => {
          if (e.response) {
            console.log(e.response)
            console.log(e.response.data.code)
            // console.log(e.response.data.message)
            // if (e.response.status >= 500) {
            //   alert(e.response.data.Message)
            // } else {
            //   alert(e.message)
            // }
          } else if (e.request) {
            console.log('no response from server.')
            console.log('Message:' + e.message)
          } else {
            console.log('getMasterData error!:' + e.message)
          }
          callBack(e.response)
        })
  },
  postAccessLog(item, callBack){
    return callLogApi(null, item,callBack)
        //.post('/accesslog',item)
        .then((response) => {
          callBack(response)
        })
        .catch((e) => {
          callBack(e.response)
        })
  }
}
